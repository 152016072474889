<template>
  <div class="relative">
    <div class="view-header pt-1">
      <h2 class="heading-medium">Videos Tags</h2>

      <div
        v-if="loaded"
        class="flex flex-column mt-2">
        <f-button
          class="f-btn-add ml-auto no-shrink mb-3 mt-2"
          theme="light"
          @click.prevent="$bus.$emit('video-tag-popup-show', null, tags)">
          Add Tag
        </f-button>

        <validation-observer
          ref="tags-filters-form"
          tag="form"
          class="search-form flex flex-align-items-center">
          <f-input-wrapper
            prependIcon="search"
            class="mr-2 full-width">
            <input
              type="search"
              v-model="filters.search"
              placeholder="Search by Name"/>
          </f-input-wrapper>

          <div class="search-form-buttons flex ml-auto">
            <f-button
              theme="primary"
              :big="true"
              class="m2-1 mb-1-ms ml-0-sm text-nowrap"
              @click.prevent="validateFiltersForm">
              Search
            </f-button>

            <f-button
              theme="warning"
              :big="true"
              class="ml-1 text-nowrap"
              :disabled="resetFiltersDisabled"
              @click.prevent="clearFilters">
              Reset filters
            </f-button>
          </div>
        </validation-observer>
      </div>
    </div>

    <f-list
      ref="tags-list"
      class="tags-list"
      :loaded="loaded"
      :listSettings="listSettings"
      :listItems="tags" />

    <pagination
      :class="{'is-hidden': !loaded}"
      :meta-data="paginationMetaData" />

    <div
      v-if="!loaded && !loadError"
      class="loading-spinner-wrapper">
      <div class="loading-spinner pb-3 pt-3">
        <span class="loading-spinner-content">
          Loading data&hellip;
        </span>
      </div>
    </div>

    <f-alert
      v-if="loadError"
      visible
      class="my-5"
      theme="warning">
      <span>
        Loading data failed.
        <a
          href="javascript:window.location.reload();"
          class="lnk lnk-alert">
          Reload page
        </a>
        to try again.
      </span>
    </f-alert>

    <video-tag-popup />
  </div>
</template>


<script>
import debounce from 'lodash.debounce';
import HasFilters from '@/mixins/HasFilters.vue';
import HasPagination from '@/mixins/HasPagination.vue';
import Pagination from '@/components/core/Pagination.vue';
import FormUtils from '@/utils/FormUtils.js';
import ListUtils from '@/utils/ListUtils.js';
import VideoTagPopup from '@/components/popups/VideoTagPopup.vue';

export default {
  name: 'videos-tags',
  mixins: [
    HasFilters,
    HasPagination
  ],
  components: {
    'pagination': Pagination,
    'video-tag-popup': VideoTagPopup
  },
  computed: {
    resetFiltersDisabled () {
      return this.filters.search === '';
    }
  },
  data () {
    return {
      listSettings: [
        {
          title: 'Name',
          variableName: ['name'],
          type: 'String'
        },
        {
          title: '',
          variableName: ['actonsList'],
          type: 'actions',
          items: [
            {
              disabled: false,
              callback: this.editTag,
              label: 'Edit',
              name: 'edit',
              theme: 'outline'
            },
            {
              disabled: false,
              callback: this.deleteTag,
              label: 'Delete',
              name: 'delete',
              theme: 'outline-danger'
            }
          ]
        }
      ],
      tags: [],
      loaded: false,
      loadError: false,
      removeInProgress: false,
      filters: {
        search: ''
      }
    }
  },
  mounted () {
    this.$bus.$on('videos-tags-reload', this.loadFilteredData);
    this.loadFilteredData();
  },
  methods: {
    debouncedLoadFilteredData: debounce(function () {
      this.$bus.$emit('view-filters-save');
      this.loadFilteredData();
    }, 250),
    loadFilteredData () {
      let requestParams = {
        is_pagination: 1,
        page: this.currentPage
      };

      if (this.filters.search && this.filters.search.length) {
        requestParams.search = this.filters.search;
      }

      ListUtils.loadItemsData(this, {
        endpoint: '/api/video/tag/get-all',
        method: 'get',
        listField: 'tags',
        params: requestParams
      });
    },
    editTag (item) {
      if (item.id) {
        this.$bus.$emit('video-tag-popup-show', item);
      }
    },
    deleteTag (item) {
      if (item.id) {
        this.removeInProgress = true;

        ListUtils.removeItem(this, {
          endpointBase: '/api/video/tag/remove/',
          id: item.id,
          successTitle: 'Tag deleted',
          successText: 'Selected tag has been deleted',
          confirmTitle: 'Are you sure you want to delete this tag?',
          confirmText: 'This operation cannot be undone',
          confirmButtonColor: '#E0211B',
          errorTitle: 'An error has occurred',
          errorText: 'Deletion of selected tag has failed. Please try again.',
          successAction: () => {
            this.removeInProgress = false;
            this.loadFilteredData();
          },
          cancelAction: () => {
            this.removeInProgress = false;
          }
        });
      }
    },
    validateFiltersForm () {
      this.$bus.$emit('pagination-reset');
      FormUtils.validate(this.$refs['tags-filters-form'], this.debouncedLoadFilteredData);
    },
    clearFilters () {
      this.loaded = false;
      this.loadError = false;
      this.filters = {
        search: ''
      };

      this.loadFilteredData();
      this.$bus.$emit('view-filters-reset');
    },
  },
  beforeDestroy () {
    this.$bus.$off('videos-tags-reload', this.loadFilteredData);
  }
}
</script>
